import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../theme'

const BlogIndex = ({ data, location }) => {
  const siteTitle = 'Jayla + Artur'
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allContentfulBlogPost.edges

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.node.title
            const image = post.node.featuredImage.gatsbyImageData

            return (
              <li key={post.node.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <GatsbyImage image={image} alt="" />
                    <h2>
                      <Link to={post.node.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.node.summary.summary,
                      }}
                      itemProp="description"
                    />
                  </section>
                  <small>{post.node.date}</small>
                </article>
              </li>
            );
          })}
        </ol>
      </Layout>
    </ThemeProvider>
  );
}

export default BlogIndex

export const pageQuery = graphql`
  {
    allContentfulBlogPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          summary {
            summary
          }
          featuredImage {
            gatsbyImageData(
              width: 630
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//         }
//       }
//     }
//   }
// `
